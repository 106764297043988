// @flow

import { graphql } from 'gatsby';
import { rem } from 'polished';
import * as React from 'react';
import styled from 'styled-components';
import { Dimen } from '../constants';
import Section from './Section';
import TextP from './TextP';

import type { FeatureSection_featureSectionConnection } from '../__generated__/FeatureSection_featureSectionConnection';

type Props = $ReadOnly<{|
  featuresSection: FeatureSection_featureSectionConnection,
|}>;

const logoWidth = 80;
const logoHeight = 70;

const SectionFeatures = ({ featuresSection }: Props) => {
  return (
    <Section>
      <StyledWrapper>
        <StyledFeatures>
          {(featuresSection.features || []).filter(Boolean).map(feature => {
            const url = feature.image?.url;
            const src =
              url && `${url}?fit=max&w=${logoWidth * 2}&h=${logoHeight * 2}`;

            return (
              <div key={feature.id}>
                <StyledImage>
                  <img src={src} alt={feature.heading} />
                </StyledImage>
                <TextP>{feature.heading}</TextP>
                <TextP small>
                  {/* 2024-09: <p> tags have started unexpected appearing in CMS */}
                  {feature.description.replace(/<[^>]+>/g, '')}
                </TextP>
              </div>
            );
          })}
        </StyledFeatures>
      </StyledWrapper>
    </Section>
  );
};

export default SectionFeatures;

export const fragment = graphql`
  fragment FeatureSection_featureSectionConnection on DatoCmsFeatureSection {
    features {
      id
      heading
      image {
        url
      }
      description
    }
  }
`;

const StyledWrapper = styled('div')`
  text-align: center;
  margin: ${rem(Dimen.spacing * 1.5)} 0 ${rem(Dimen.spacing)};
`;

const StyledFeatures = styled('div')`
  display: flex;
  justify-content: center;

  > div {
    flex: 1;
    max-width: ${rem(420)};
    margin: 0 ${rem(Dimen.spacing / 2)};

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: 720px) {
    flex-direction: column;
    align-items: center;

    > div {
      margin-bottom: ${rem(Dimen.spacing)};
    }
  }
`;

const StyledImage = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${rem(logoHeight)};

  > img {
    display: block;
    max-width: ${rem(logoWidth)};
    max-height: ${rem(logoHeight)};
  }
`;
